import React from "react";
import "react-image-lightbox/style.css";
import { IPlayer } from "../store/GameStore";
import { CardPlaceholder } from "./CardPlaceholder";
import { CardsGroup } from "./CardsGroup";

export interface ICardsInHandProps {
    cards: string[];
    cardAction: (cardId: string) => void;
    players: IPlayer[];
}

export const CardsInHand: React.FC<ICardsInHandProps> = (props) => {
    return (
        <div className="row">
            <CardsGroup
                cards={props.cards}
                cardAction={props.cardAction}
                actionButtonLabel="Vybrat"
                players={props.players}
                unveiledCards={null}
            />
            { props.cards.length < 6 && <div className="col"><CardPlaceholder /></div>}
        </div>
    );
};