import * as React from "react";
import { IGame, GameStateEnum, IPlayer } from "../store/GameStore";
import { CardsInHand } from "./CardsInHand";
import { CardsOnTable } from "./CardsOnTable";

export interface IGameTableProps extends IGame {
    chooseCard: (cardId: string) => void;
    voteForCard: (cardId: string) => void;
}

const getHeaderMessage = (state: GameStateEnum, currentPlayer: IPlayer): string => {

    switch (state) {
        case GameStateEnum.NarratorIsChoosingCard:
            return currentPlayer.isNarrator ? "Vyber kartu a řekni nápovědu" : "Vypravěč vybírá kartu, poslouchej nápovědu vypravěče";
        case GameStateEnum.PlayersAreChoosingCard:
            return currentPlayer.isNarrator ? "Ostatní hráči vybírají své karty" : "Vyber kartu odpovídající nápovědě";
        case GameStateEnum.Voting:
            return currentPlayer.isNarrator ? "Ostatní hráči hlasují pro karty" : "Hlasuj pro kartu, která podle tebe patří vypravěči";
        case GameStateEnum.EndOfRound:
            return "Bodování";
        default:
            return "";
    }
};

export const GameTable: React.FC<IGameTableProps> = (props) => {
    const currentPlayer = props.players.find(p => p.isMe);

    return (
        <>
            <div className="table-header">
                <h3>{currentPlayer && getHeaderMessage(props.state, currentPlayer)}</h3>
            </div>
            <div className="table-cards my-3">
                <h4>Karty na stole</h4>
                <CardsOnTable cards={props.cardsOnTable} cardAction={props.voteForCard} players={props.players} state={props.state} />
            </div>
            <div className="hand-cards my-3">
                <h4>Karty v ruce</h4>
                {currentPlayer && <CardsInHand cards={currentPlayer.cardsInHand} cardAction={props.chooseCard} players={props.players} />}
            </div>
        </>
    );
};