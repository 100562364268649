import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { IPlayer, ICardOnTable } from "../store/GameStore";
import { CardOnTable } from "./CardOnTable";

export interface ICardsGroupProps {
    cards: string[];
    cardAction: (cardId: string) => void;
    actionButtonLabel: string;
    players: IPlayer[];
    unveiledCards: ICardOnTable[] | null;
}

export const CardsGroup: React.FC<ICardsGroupProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cardIndex, setCardIndex] = useState(0);

    const handleActionClick = (cardId: string) => {
        props.cardAction(cardId);
        setIsOpen(false);
    }

    const handleCardClick = (cardId: string) => {
        setCardIndex(props.cards.indexOf(cardId));
        setIsOpen(true);
    }

    const getPlayer = (id: string): IPlayer | undefined=> {
        return props.players.find(p => p.playerId === id);
    }

    const getPlayerName = (id: string) : string => {
        const player = getPlayer(id);
        return player && player.name || '';
    }

    return (
        <>
{props.unveiledCards ? (props.unveiledCards.map(card => {
                let player = getPlayer(card.playerId);
                let playerName = null;
                let narrator = null;
                let score = null;
                if (player) {
                    playerName = player.isMe ? (<span className="text-success">{player.name}</span>) : (<>{player.name}</>);
                    narrator = player.isNarrator ? (<span title="Vypravěč">👑</span>) : <></>;
                    score = <>(+{ player.lastRoundScore })</>;
                }
                return (
                    <div className="col">
                        <strong>{narrator} {playerName} {score}</strong>
                        <CardOnTable onClick={handleCardClick}
                            cardId={card.cardId}
                        />
                        <>{card.votes.map(v => <>{getPlayerName(v)}<br/></>)}</>
                    </div>
                )
            })) : (props.cards.map(card => {
                return (
                    <div className="col">
                        <CardOnTable onClick={handleCardClick}
                            cardId={card}
                        />
                    </div>
                )
            }))}
{!!isOpen && (
                <Lightbox
                    mainSrc={`./cards/${props.cards[cardIndex]}.jpg`}
                    nextSrc={props.cards[(cardIndex + 1) % props.cards.length]}
                    prevSrc={props.cards[(props.cards.length - 1) % props.cards.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setCardIndex(
                            (cardIndex + props.cards.length - 1) % props.cards.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setCardIndex((cardIndex + 1) % props.cards.length)
                    }
                    toolbarButtons={[<button className="btn btn-success btn-lg mb-3" onClick={() => handleActionClick(props.cards[cardIndex])}>{props.actionButtonLabel}</button>]}
                    enableZoom={false}
                />
            )}
</>
    );
};
