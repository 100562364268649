import * as React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './GameHeader.css';
import { IGame } from '../store/GameStore';

interface IGameHeaderProps {
    isLoading: boolean,
    gameId: string,
    game: IGame | null,
}

export default class GameHeader extends React.PureComponent<IGameHeaderProps> {
    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container fluid>
                        <NavbarBrand tag={Link} to="/">Dixit</NavbarBrand>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
