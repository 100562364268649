import * as React from 'react';
import * as GameStore from '../store/GameStore';

interface IGamePlayersProps {
    game: GameStore.IGame
}

export default class GamePlayers extends React.PureComponent<IGamePlayersProps> {
    public render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        Hráči
                    </div>
                    <ul className="list-group list-group-flush">
                        {this.props.game.players.map((player) => {
                            let isNarrator = null;
                            let status = null;
                            if (player.isNarrator) {
                                isNarrator = <span title="Vypravěč">👑</span>
                            }
                            if (!player.isNarrator && this.props.game.state === GameStore.GameStateEnum.PlayersAreChoosingCard) {
                                status = this.props.game.playersChoseCard.indexOf(player.playerId) < 0
                                    ? <span title="Vybírá kartu" className="rotate">⏳</span>
                                    : <span title="Vybral kartu">🗸</span>
                            }
                            if (!player.isNarrator && this.props.game.state === GameStore.GameStateEnum.Voting) {
                                status = this.props.game.playersVoted.indexOf(player.playerId) < 0
                                    ? <span title="Hlasuje" className="rotate">⏳</span>
                                    : <span title="Hlasoval">🗸</span>
                            }
                            if (!player.isConnected) {
                                status = <span className="text-danger" title="Odpojen">{"\u25CF"}</span>
                            }
                            let isMeClass = '';
                            if (player.isMe) {
                                isMeClass = "font-weight-bold text-success";
                            }
                            let lastRoundScore = null;
                            if (this.props.game.state === GameStore.GameStateEnum.EndOfRound) {
                                lastRoundScore =  <>(+{ player.lastRoundScore })</>;
                            }

                            return <li className={"list-group-item " + isMeClass} key={player.playerId}>{status}{isNarrator} {player.name} {player.score} b {lastRoundScore}</li>
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}