import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ReactGA from 'react-ga';
import { Constants } from '../store/Constants'
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';

interface ICreateGameState {
    gameId: string,
    generated: boolean,
}

export default class CreateGame extends React.PureComponent<RouteComponentProps, ICreateGameState> {
    constructor(props: RouteComponentProps) {
        super(props);
        ReactGA.initialize(Constants.GA_MeteringId);
        ReactGA.pageview("/");

        this.state = {
            gameId: localStorage.getItem("lastgameid") || "",
            generated: false,
        };
    }

    public render() {
        return (
            <div>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                        <Container fluid>
                            <NavbarBrand tag={Link} to="/">Dixit</NavbarBrand>
                        </Container>
                    </Navbar>
                </header>
                <h3 className="text-center mt-5 mb-4">Založte novou hru nebo se připojte k probíhající hře</h3>
                <div className="justify-content-center mb-2 form-inline">
                    <label className="form-control-lg mr-1">Identifikátor hry</label>
                    <input className="form-control form-control-lg" type="text" key="playerName" value={this.state.gameId}
                        onChange={this.onNameChanged.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} placeholder="Zadejte svůj identifikátor hry" />
                    <button className="btn btn-light btn-lg ml-2" onClick={this.generateId.bind(this)}>Vygenerovat</button>
                </div>
                <div className="text-center mt-3 mb-5">
                    <button className="btn btn-success btn-lg" onClick={this.createGame.bind(this)}>Pokračovat</button>
                </div>
            </div>
        );
    }

    private generateId() {
        this.setState({
            gameId: Math.random().toString(36).substring(7),
            generated: true,
        });
        localStorage.removeItem("lastgameid");
    }

    private createGame() {
        const id = this.state.gameId.trim();
        if (id.length > 0) {
            if (!this.state.generated) {
                localStorage.setItem("lastgameid", id);
            }
            this.props.history.push('/play/' + encodeURIComponent(id))
        }
    }

    private handleKeyDown(e: any) {
        if (e.key === 'Enter') {
            this.createGame();
        }
    }

    private onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            gameId: event.target.value,
            generated: false,
        });
    }
}
