import * as React from 'react';

interface IGameConnectProps {
    playerName: string,
    gameId: string,
    connectPlayer(): any,
    changePlayerName(name: string): any,
}

export default class GameConnect extends React.PureComponent<IGameConnectProps> {
    public render() {
        if (this.props.gameId.length > 0) {
            return (
                <div>
                    <h3 className="text-center mt-5">Chystáte se připojit do hry <strong>"{this.props.gameId}"</strong></h3>
                    <div className="justify-content-center mb-5 mt-5 form-inline">
                        <label className="form-control-lg mr-1">Přezdívka</label>
                        <input className="form-control form-control-lg" type="text" key="playerName" value={this.props.playerName}
                            onChange={this.onNameChanged.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} placeholder="Zadejte svoji přezdívku" />
                        <button className="btn btn-success btn-lg ml-2" onClick={this.props.connectPlayer}>Připojit se</button>
                    </div>
                </div>
            );
        }
        return (<h3 className="text-center mt-5">Špatná adresa - chybí identifikátor hry.</h3>);
    }

    private handleKeyDown(e: any) {
        if (e.key === 'Enter') {
            this.props.connectPlayer();
        }
    }

    private onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.changePlayerName(event.target.value);
    }
}