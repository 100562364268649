import * as React from "react";
import { ICardOnTable, IPlayer } from "../store/GameStore";
import { CardPlaceholder } from "./CardPlaceholder";
import { CardsGroup } from "./CardsGroup";
import { GameStateEnum } from "../store/GameStore";

export interface ICardsOnTableProps {
    cards: ICardOnTable[];
    cardAction: (cardId: string) => void;
    players: IPlayer[];
    state: GameStateEnum;
}

export const CardsOnTable: React.FC<ICardsOnTableProps> = (props) => {
    const { cards, players, state } = props;

    const placeholders = [];
    for (var i = cards.length; i < players.length; i++) {
        placeholders.push(<div className="col"><CardPlaceholder /></div>);
    }

    const blankColumns = [];
    for (var j = players.length; j < 6; j++) {
        blankColumns.push(<div className="col"></div>);
    }

    const unveiledCards = (state === GameStateEnum.EndOfRound || state === GameStateEnum.EndOfGame)
        ? cards
        : null;

    return (
        <div className="row">
            <CardsGroup
                cards={cards.map(c => c.cardId)}
                cardAction={props.cardAction}
                actionButtonLabel="Hlasovat"
                players={players}
                unveiledCards={unveiledCards}
            />  

            {placeholders}
            {blankColumns}
        </div>
    );
};
